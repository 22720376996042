import React from 'react';
import PropTypes from 'prop-types';

import { toBRL } from 'utils/currency';

import InternationalTaxContingency from 'components/InternationalTaxContingency/InternationalTaxContingency';

import './InternationalTax.scss';

const InternationalTax = ({ tax }) => {
  const isContigency = !tax?.icms || !tax?.importValue || !tax?.iof;

  if (isContigency) {
    return <InternationalTaxContingency />;
  }

  return (
    <div className="InternationalTax-wrapper">
      <div className="InternationalTax-row">
        <span className="InternationalTax-text">Produtos</span>
        <span className="InternationalTax-text">{toBRL(tax?.total)}</span>
      </div>
      <div className="InternationalTax-row">
        <span className="InternationalTax-tax-text">Imposto de importação</span>
        <span className="InternationalTax-text">{toBRL(tax?.importValue)}</span>
      </div>
      <div className="InternationalTax-row">
        <span className="InternationalTax-text">ICMS</span>
        <span className="InternationalTax-text">{toBRL(tax?.icms)}</span>
      </div>
    </div>
  );
};

InternationalTax.propTypes = {
  tax: PropTypes.shape({
    icms: PropTypes.string,
    importValue: PropTypes.string,
    iof: PropTypes.string,
    total: PropTypes.string,
  }),
};

export default InternationalTax;
