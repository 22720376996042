import React, { Component } from 'react';
import PropTypes, { object } from 'prop-types';
import cookies from 'browser-cookies';
import * as routeNames from 'constants/route-names';
import Responsive from 'components/Responsive';
import { COOKIE_ZIPCODE_DELETED } from 'constants/cookies';
import './AddressModalInfo.scss';

class AddressModalInfo extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  };

  static contextTypes = {
    router: object.isRequired,
  };

  state = { showModalAddress: false };

  getCepFromCookie = () => {
    return cookies.get(COOKIE_ZIPCODE_DELETED);
  };

  getUrlSearchParamsCepInfo = () => {
    const { location } = this.props;
    const queryParams = new URLSearchParams(location.search);
    return (
      queryParams.has('zipcodeInfo') &&
      queryParams.get('zipcodeInfo') === 'true' &&
      this.getCepFromCookie()
    );
  };

  onClickCloseModalAddress = () => {
    const { router } = this.context;
    this.setState({ showModalAddress: false });
    cookies.erase(COOKIE_ZIPCODE_DELETED);
    router.replace(routeNames.address);
  };

  handleOverlayClick = (event) => {
    event.preventDefault();
    if (event.target.classList.contains('Modal-overlay')) {
      this.onClickCloseModalAddress();
    }
  };

  handleEsc(event) {
    event.preventDefault();
    if (event.key === 'Escape') {
      this.onClickCloseModalAddress();
    }
  }

  componentWillMount() {
    if (this.getUrlSearchParamsCepInfo()) {
      this.setState({ showModalAddress: true });
    }
  }

  componentDidUpdate() {
    const { showModalAddress } = this.state;
    if (!showModalAddress) {
      return document.removeEventListener('keydown', (event) => this.handleEsc(event));
    }

    document.addEventListener('keydown', (event) => this.handleEsc(event));
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', (event) => this.handleEsc(event));
  }

  render() {
    const { showModalAddress } = this.state;
    if (!showModalAddress) return null;

    return (
      <div
        className="Modal fixed inset-0 flex items-center justify-center z-50"
        onClick={this.handleOverlayClick}>
        <div className="Modal-overlay cursor-pointer" />
        <div className="relative bg-surface-container-lowest p-6 rounded-lg shadow-lg z-10 p-lg max-w-sm">
          <header className="flex pt-xl px-md pl-md pr-md mb-md inset-x-0 text-center">
            <p className="Modal-title font-md-bold w-full" id="modal-title">
              Endereço inválido
            </p>
          </header>
          <section>
            <div className="flex flex-col items-center justify-center">
              <p className="text-xs-regular text-center" id="modal-text">
                O CEP {this.getCepFromCookie()} cadastrado neste endereço é inválido ou
                não existe mais. Por favor selecione outro endereço ou cadastre um novo.
              </p>
              <button
                id="modal-confirm-button"
                className="btn block w-full text-center mt-md"
                onClick={this.onClickCloseModalAddress}>
                Entendi
              </button>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Responsive(AddressModalInfo);
