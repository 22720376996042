import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import isEmpty from 'lodash/isEmpty';

import { Element } from 'react-scroll';

import NewAddressForm from 'components/Address/NewAddressForm/NewAddressForm';
import * as customerMessages from 'constants/customer-messages';
import ErrorModal from 'pages/AddressPage/ErrorModal';
import * as addressActions from 'reducers/address';
import * as cleaners from 'utils/value-cleaners';
import * as validators from 'utils/validators';
import { addressSelectContent, addressViewContent } from 'utils/data-layer/helpers';

const { string, object, func, bool } = PropTypes;

const mapStateToProps = ({ form, address }) => ({ form, address });
const mapDispatchToActions = { ...addressActions, changeField: change };

export const formFields = [
  'zipcode',
  'address',
  'number',
  'complement',
  'neighbourhood',
  'city',
  'state',
  'reference',
  'telephone',
  'receiver',
  'isDefault',
  'noNumber',
  'setCustomerAddress'
];

export const handleZipcodeSubmit = (
  zipcodeValue,
  formName,
  fetchAddress,
  changeField,
  ignoreErrors = false
) => {
  return () => {
    if (!zipcodeValue) {
      return Promise.resolve();
    }

    const fieldsToUpdate = [
      'address',
      'neighbourhood',
      'city',
      'state',
      'reference',
      'number',
      'complement',
    ];

    return fetchAddress(cleaners.zipcode(zipcodeValue), ignoreErrors)
      .then((address = {}) => {
        const { address: street } = address;

        if (!street) {
          addressViewContent({ contentId: 'aviso:cep-nao-retornou-todos-campos' });
        }

        fieldsToUpdate.forEach((key) => changeField(formName, key, address[key]));

        return address;
      });
  };
};

export const handleZipcodeChange = (resetAddress, fields) => {
  return (event) => {
    const fieldsToUpdate = [
      'address',
      'neighbourhood',
      'city',
      'state',
      'reference',
      'number',
      'complement',
    ];

    event.preventDefault();

    resetAddress();
    fieldsToUpdate.forEach((key) => fields[key]?.onChange(''));
  };
};

export const errorsAddressForm = ({
  zipcode,
  address,
  receiver,
  telephone,
  number,
  neighbourhood,
  city,
  state,
  reference,
  noNumber,
}) => {
  const errors = {};

  if (!zipcode || !validators.zipcode(cleaners.zipcode(zipcode))) {
    errors.zipcode = customerMessages.invalidZipcode;
  }

  if (!address) {
    errors.address = customerMessages.invalidAddress;
  }

  if (!receiver || !validators.fullName(receiver)) {
    errors.receiver = customerMessages.invalidReceiver;
  }

  if (validators.isNumber(receiver)) {
    errors.receiver = customerMessages.nameWithNumbers;
  }

  if (!telephone || !validators.telephoneCustomerArea(cleaners.onlyNumbers(telephone))) {
    errors.telephone = customerMessages.invalidTelephone;
  }

  if (!noNumber && !number) {
    errors.number = customerMessages.invalidNumber;
  }

  if (!neighbourhood) {
    errors.neighbourhood = customerMessages.invalidNeighbourhood;
  }

  if (!city) {
    errors.city = customerMessages.invalidCity;
  }

  if (!state) {
    errors.state = customerMessages.invalidState;
  }

  if (!reference || reference.trim().length < 5) {
    errors.reference = customerMessages.invalidReference;
  }

  return errors;
};

export default function addressFormContainerFactory(formName) {
  // We don't have a reduxForm decorator here, because we use it on different
  // places. In those places we call it with a different configs and name.
  class AddressFormContainer extends Component {
    static displayName = `AddressFormContainer-${formName}`;

    static propTypes = {
      form: object.isRequired,
      address: object.isRequired,
      fields: object.isRequired,
      fetchAddress: func.isRequired,
      resetAddress: func.isRequired,
      handleSubmit: func.isRequired,
      className: string,
      closedLabelText: string,
      closedCancelComponent: object,
      openCancelComponent: object,
      submit: func.isRequired,
      canChangeZipcode: bool,
      dismissEditAddress: func.isRequired,
      changeField: func.isRequired,
    };

    state = {
      openErrorModal: false,
      openZipCodeErrorModal: false,
    }

    handleSelfZipcodeSubmit = () => {
      const { fields, fetchAddress, changeField } = this.props;

      return handleZipcodeSubmit(
        fields.zipcode.value,
        formName,
        fetchAddress,
        changeField,
        true
      );
    };

    handleZipcodeSubmitClick = () => {
      const { dismissEditAddress } = this.props;

      return this.handleSelfZipcodeSubmit()()
        .then((address) => {
          dismissEditAddress();
          this.setState({ zipCodeNotFound: false });
          return address;
        })
        .catch((err) => {
          if (err.status !== 404) {
            this.setState({ openZipCodeErrorModal: true });
            return;
          }

          this.setState({ zipCodeNotFound: true });
        });
    };

    handleChangeNoNumber = () => {
      const { changeField } = this.props;

      changeField(formName, 'number', '');
    }

    render() {
      const {
        fields,
        address: { address, neighbourhood, city, state, isDefault },
        handleSubmit,
        openCancelComponent,
        submit,
        hasCustomerAddress,
        title,
      } = this.props;

      const { zipCodeNotFound } = this.state;

      const zipcode = zipCodeNotFound ? { ...fields.zipcode, error: 'CEP não encontrado!', invalid: true } : fields.zipcode;

      const finalFields = Object.assign({}, fields, {
        address: Object.assign({}, fields.address, { disabled: !!address }),
        neighbourhood: Object.assign({}, fields.neighbourhood, {
          disabled: !!neighbourhood,
        }),
        zipcode,
        city: Object.assign({}, fields.city, { disabled: !!city }),
        state: Object.assign({}, fields.state, { disabled: !!state }),
      });

      let gaObj =
        '{"category": "Novo endereco", "action": "Continuar", "label": "Clique"}';

      if (formName === 'editAddress') {
        gaObj =
          '{"category": "Endereco", "action": "Novo endereco", "label": "Salvar"}';
      }

      return (
        <div>
          <form
            method="post"
            className="AddressFormContainer p-md md:p-xlg"
            onSubmit={handleSubmit((values) => {
              return new Promise((resolve, reject) => {
              // If we do not have value for state we are on closed form.
              // If we are on closed form, submit should only fetch address.
                if (!finalFields.state.value) {
                  this.handleSelfZipcodeSubmit()().then(resolve, reject);
                } else {
                  const cleanValues = Object.assign({}, values, {
                    telephone: cleaners.onlyNumbers(values.telephone),
                    zipcode: cleaners.zipcode(values.zipcode),
                  });

                  const errors = Object.assign(
                    {},
                    errorsAddressForm(cleanValues)
                  );

                  if (!isEmpty(errors)) {
                    return reject(errors);
                  }

                  submit(cleanValues)
                    .then(resolve)
                    .catch((err) => {
                      addressViewContent({ contentId: `erro: Não foi possível salvar o endereço | status:${err.status}` });
                      this.setState({ openErrorModal: true });
                      reject(err);
                    });
                }
              });
            })}
          >
            <Element className="flex justify-center w-full" name={formName}>
              <NewAddressForm
                {...finalFields}
                title={title}
                formName={formName}
                handleZipcodeSubmit={this.handleZipcodeSubmitClick}
                isDefaultAddress={isDefault}
                hasCustomerAddress={hasCustomerAddress}
              >
                <div className="flex justify-end mt-lg">
                  {openCancelComponent}
                  <button id="address-form-submit" type="submit" className="btn btn-success" data-ga={gaObj}>
                  Salvar e continuar
                  </button>
                </div>
              </NewAddressForm>
            </Element>
          </form>
          <ErrorModal
            open={this.state.openErrorModal}
            onClose={() => this.setState({ openErrorModal: false })}
            closeText="Entendi"
            message="Desculpe, ocorreu um problema inesperado em nosso sistema. Tente novamente em alguns instantes."
            title="Algo deu errado"
          />
          <ErrorModal
            open={this.state.openZipCodeErrorModal}
            onClose={() => this.setState({ openZipCodeErrorModal: false })}
            closeText="Entendi"
            message="Desculpe, não foi possível consultar o CEP informado. tente novamente em alguns instantes."
            title="Falha na verificação"
          />
        </div>
      );
    }
  }

  return connect(mapStateToProps, mapDispatchToActions)(AddressFormContainer);
}
