import React from 'react';
import PropTypes from 'prop-types';

import {
  delivery as deliveryRoute,
  address as addressRoute,
} from 'constants/route-names';
import { NATIONAL_STORAGE_KEY } from 'constants/cookies';

import {
  reviewAddressDescription as reviewAddressDescriptionMasker
} from 'utils/maskers';

import ButtonEdit from 'components/Form/ButtonEdit/ButtonEdit';
import WarningIcon from 'icons/icon-warning-amber.svg';

import './OrderReviewAddress.scss';

const OrderReviewAddress = ({
  number: addressNumber,
  address,
  state,
  complement = '',
  city,
  isStore = false,
  startIndex,
  endIndex,
  isMobile
}) => {
  const dataGA = JSON.stringify({
    'category': 'Revisao do pedido',
    'action': 'Alterar endereco',
    'label': '',
  });

  return (
    <div className="OrderReviewAddress">
      <div className="OrderReviewAddress-description">
        {reviewAddressDescriptionMasker({
          startIndex,
          endIndex,
          isStore,
          address,
          addressNumber,
          complement,
          city,
          state
        })}
        {isStore &&
          <ButtonEdit
            className="OrderReviewAddress-description-edit"
            label={isMobile? 'Editar entrega': 'Alterar entrega'}
            to={{
              pathname: `/${deliveryRoute}`,
            }}
          /> ||
          <ButtonEdit
            className="OrderReviewAddress-description-edit"
            label={isMobile? 'Editar entrega':'Alterar endereço'}
            to={{
              pathname: `/${addressRoute}`,
              query: { showAll: 'true' },
            }}
            handleClick={function handleClick(e) {
              if (sessionStorage.getItem(NATIONAL_STORAGE_KEY)) {
                e.preventDefault();
                return window.location = '/n#/endereco/editar';
              }
            }}
            data-ga={dataGA}
          />
        }
      </div>
    </div>
  );
};

OrderReviewAddress.propTypes = {
  number: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  complement: PropTypes.string,
  city: PropTypes.string.isRequired,
  isStore: PropTypes.bool,
  startIndex: PropTypes.number.isRequired,
  endIndex: PropTypes.number.isRequired,
  isMobile: PropTypes.bool
};

export default OrderReviewAddress;
