import * as AddressApi from 'api/address-api';
import * as customerMessages from 'constants/customer-messages';
import { fail } from './fail';
import { unauthorizedLogout } from './unauthorized';

export const REQUEST = 'address-list/REQUEST';
export const REQUEST_DELETE = 'address-list/REQUEST_DELETE';
export const RECEIVED = 'address-list/RECEIVED';
export const FINISHED = 'address-list/FINISHED';
export const FINISHED_DELETING = 'address-list/FINISHED_DELETING';

export const initialState = {
  isRequesting: false,
  isDeleting: false,
  addressList: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return Object.assign({}, state, { isRequesting: true });
    case REQUEST_DELETE:
      return Object.assign({}, state, { isDeleting: true });
    case RECEIVED:
      const { addressList } = action;
      return Object.assign({}, state, {
        isRequesting: false,
        isDeleting: false,
        addressList,
      });
    case FINISHED_DELETING:
      return Object.assign({}, state, { isDeleting: false });
    case FINISHED:
      return Object.assign({}, state, { isRequesting: false });
    default:
      return state;
  }
}

export function requestAddressList() {
  return { type: REQUEST };
}

export function requestDelete() {
  return { type: REQUEST_DELETE };
}

export function receiveAddressList(addressList) {
  return { type: RECEIVED, addressList };
}

export function finish() {
  return { type: FINISHED };
}

export function finishDelete() {
  return { type: FINISHED_DELETING };
}

export function fetchAddressList({ showAll }) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const { addressList = {} } = getState();

      if (addressList.isRequesting) {
        return reject(new Error('Already fetching address list.'));
      }

      dispatch(requestAddressList());

      return AddressApi.getAddressList(showAll).then(
        (res) => {
          dispatch(receiveAddressList(res));
          return resolve(res);
        },
        (err) => {
          const { status } = err;

          if (status === 401) {
            dispatch(unauthorizedLogout(err));
            dispatch(fail(customerMessages.expired, status));
          } else if (status === 406) {
            dispatch(fail(customerMessages.incompleteRegister, status));
          }

          dispatch(finish());
          return reject(err);
        }
      );
    });
}

export const deleteAddress = (addressUuid) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { addressList = {} } = getState();

    if (addressList.isRequesting || addressList.isDeleting) {
      return reject(new Error('Another request is in progress.'));
    }

    dispatch(requestDelete());

    return AddressApi.deleteAddress(addressUuid).then(
      (res) => {
        return resolve(res);
      },
      (err) => {
        dispatch(finishDelete());
        return reject(err);
      }
    );
  });
