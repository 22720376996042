import VMasker from 'vanilla-masker';
import range from 'lodash/range';
import moment from 'moment';

import { DEFAULT_MASK_CREDIT_CARD } from './card';
import * as cleaners from './value-cleaners';

import * as deliveryChoices from '../app/constants/delivery-choices';
import * as scheduledPeriods from '../app/constants/scheduled-periods';
import { deliveryOneHour } from '../app/constants/customer-messages';

moment.locale('pt-br');

export const zipcode = (value) => {
  const onlyNumbers = cleaners.onlyNumbers(value);
  return onlyNumbers && VMasker.toPattern(onlyNumbers, '99999-999');
};

export const cpf = (value) => {
  const onlyNumbers = cleaners.onlyNumbers(value);
  return onlyNumbers && VMasker.toPattern(onlyNumbers, '999.999.999-99');
};

export const telephone = (value) => {
  if (!value) {
    return value;
  }

  const onlyNumbers = cleaners.onlyNumbers(value);
  let pattern = '(99) 9999-9999';

  if (onlyNumbers.length >= 11) {
    pattern = '(99) 9-9999-9999';
  }

  return VMasker.toPattern(onlyNumbers, pattern);
};

export const mobilePhone = (value) => {
  const onlyNumbers = cleaners.onlyNumbers(value);
  return onlyNumbers && VMasker.toPattern(onlyNumbers, '(99) 9-9999-9999');
};

export const mobilePhoneApp = (value) => {
  const onlyNumbers = cleaners.onlyNumbers(value);
  return onlyNumbers && VMasker.toPattern(onlyNumbers, '(99) 99999-9999');
};

export const birthDate = (value) => {
  const onlyNumbers = cleaners.onlyNumbers(value);
  return onlyNumbers && VMasker.toPattern(onlyNumbers, '99/99/9999');
};

export const address = (value) => {
  return value && value.toString().split(' ')
    .map((string) => {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    })
    .reduceRight((string, acc) => {
      return `${acc} ${string}`;
    }, '')
    .trim()
    .substr(0, 50);
};
export const cityWithState = (city, state) => {
  return city && state && `${address(city)}/${state.toUpperCase()}` || '';
};

export const cnpj = (value) => {
  const onlyNumbers = cleaners.onlyNumbers(value);
  return onlyNumbers && VMasker.toPattern(onlyNumbers, '99.999.999/9999-99');
};

export const fullAddress = (addressStr, neighbourhood, city, state, number, complement, reference) => {
  let fullAddressStr = '';

  if (addressStr) {
    fullAddressStr = `${address(addressStr)}`;
  }
  if (number) {
    fullAddressStr = `${fullAddressStr}, ${address(number)}`;
  }
  if (complement) {
    fullAddressStr = `${fullAddressStr} - ${address(complement)}`;
  }
  if (neighbourhood) {
    fullAddressStr = `${fullAddressStr} - ${address(neighbourhood)}`;
  }
  if (reference) {
    fullAddressStr = `${fullAddressStr} - ${address(reference)}`;
  }
  if (fullAddressStr) {
    fullAddressStr = `${fullAddressStr} -`;
  }
  fullAddressStr = `${fullAddressStr} ${cityWithState(city, state)}`;

  return fullAddressStr;
};

export const deliveryAddress = (addressStr, number, complement, city, state, reference) => {
  const complementStr = complement && `, ${complement}` || '';
  const referenceStr = reference && `, ${reference}` || '';
  return `${address(addressStr)}, ${number}${complementStr}${referenceStr} - ${cityWithState(city, state)}`;
};

export const fullName = (value) => {
  return value && value.substr(0, 50);
};

export const addressReceiver = (value) => {
  return value && value.substr(0, 50);
};

export const addressNumber = (value, _, allValues) => {
  if (allValues?.noNumber) {
    return undefined;
  }

  const onlyNumbers = cleaners.onlyNumbers(value);
  return onlyNumbers && onlyNumbers.substr(0, 5);
};

export const booleanValue = (value) => {
  return value || value === 'true';
};

export const addressInput = (value) => {
  return value && value.substr(0, 50);
};

export const addressComplement = (value) => {
  return value && value.substr(0, 20);
};

export const addressNeighbourhood = (value) => {
  return value && value.substr(0, 30);
};

export const addressCity = (value) => {
  return value && value.substr(0, 30);
};

export const addressState = (value) => {
  return value && value.toUpperCase().substr(0, 2);
};

export const addressReference = (value) => {
  return value && value.substr(0, 60);
};

export const companyName = (value) => {
  return value && value.substr(0, 50);
};

export const fantasyName = (value) => {
  return value && value.substr(0, 50);
};

export const stateRegistration = (value) => {
  const onlyNumbers = cleaners.onlyNumbers(value);
  return onlyNumbers && onlyNumbers.substr(0, 20);
};

export const addLeadingZero = (value) => {
  if (typeof value === 'string' && !value || value.length >= 2) {
    return value;
  } else if (typeof value === 'number' && (value > 9 || value < 0)) {
    return value.toString();
  }

  return `0${value.toString()}`;
};

export const cvc = (value) => {
  if (!value) {
    return value;
  }

  const onlyNumbers = cleaners.onlyNumbers(value);

  return onlyNumbers.substr(0, 4);
};

export const cardNumber = (value) => {
  if (!value) {
    return value;
  }

  const onlyNumbers = cleaners.onlyNumbers(value);

  return VMasker.toPattern(onlyNumbers, DEFAULT_MASK_CREDIT_CARD);
};

export const fullNameOnlyCharacters = (value) => {
  if (!value) {
    return value;
  }

  const onlyCharacters = cleaners.onlyCharactersAndSpace(value);
  return onlyCharacters.substr(0, 50);
};

export const cpfOrCnpj = (value) => {
  const onlyNumbers = cleaners.onlyNumbers(value);
  if (!onlyNumbers) {
    return onlyNumbers;
  }

  if (onlyNumbers.length === 11) {
    return cpf(onlyNumbers);
  }

  if (onlyNumbers.length > 11) {
    return cnpj(onlyNumbers.substr(0, 14));
  }

  return onlyNumbers;
};

export const installmentDescription = (value) => {
  return value && value.split(' ').slice(0, 2).join(' ');
};

export const withGiftsTitle = (values) => {
  return values && values.length && values.reduceRight((acc, g) => ` + Brinde: ${g.title}${acc}`, '') || '';
};

export const withWarrantyTitle = (warranty) => {
  return warranty && warranty.name && `com ${warranty.name}` || '';
};

export const withVariation = (variations = [], title) => {
  return variations.length && variations.filter(v => title.toLowerCase().split(' ').indexOf(v.value.toLowerCase()) === -1).reduceRight((acc, v) => ` - ${v.value}${acc}`, '') || '';
};

export const withServiceTitle = (services) => {
  return services && services.length > 0 && ` com ${services.map(s => s.name).join(' e ')}` || '';
};

export const fullProductDescription = ({
  quantity,
  title,
  reference,
  warranty,
  gifts,
  variations,
  services
}) => {
  const titleWithReference = reference && `${title} - ${reference}` || title;
  return `${addLeadingZero(quantity)} ${titleWithReference} ${withVariation(variations, titleWithReference)} ${withWarrantyTitle(warranty)} ${withServiceTitle(services)} ${withGiftsTitle(gifts)}`;
};

export const fullServiceDescription = (
  quantity,
  description
) => {
  return `${addLeadingZero(quantity)} ${description}`;
};

export const reviewPackageCount = (startIndex, endIndex) => {
  let text = range(startIndex, endIndex)
    .map(i => addLeadingZero(i))
    .join(', ');

  if (startIndex < endIndex) {
    text = `${text} e ${addLeadingZero(endIndex)}`;
  } else {
    text = `${addLeadingZero(endIndex)}`;
  }

  return text;
};

export const reviewAddressDescription = ({
  startIndex,
  endIndex,
  isStore,
  address: addressStr,
  addressNumber: addressNumberStr,
  complement,
  city,
  state,
  reference
}) => {
  const shouldPluralize = startIndex < endIndex;

  const deliveryInfo = `Endereço para a${shouldPluralize && 's' || ''} entrega${shouldPluralize && 's' || ''} ${reviewPackageCount(startIndex, endIndex)}`;
  const storeInfo = 'Endereço da loja';

  return `${isStore ? storeInfo : deliveryInfo}: ${deliveryAddress(addressStr, addressNumberStr, complement, city, state, reference)}`;
};

export const metersConvert = (meters) => parseInt(meters, 10) > 999
  ? Math.round(meters / 10) / 100 + ' km'
  : `${meters} metros`;

export const deliveryDaysMessage = (days) => parseInt(days, 10) === 1 ? `${days} dia útil` : `${days} dias úteis`;

const defaultReviewMessage = (time) => (time === 0)
  ? `${deliveryOneHour}***`
  : `Em até ${deliveryDaysMessage(time)}*`;

const pickupInStoreReviewMessage = (time) => (time === 0)
  ? 'Retirar na loja hoje mesmo para compras até 14h **'
  : `Retirar na loja a partir de ${deliveryDaysMessage(time)}*`;

export const scheduledReviewMessage = (scheduledParameters) => {
  return `Agendada para ${moment(scheduledParameters.date).format('DD/MM/YYYY')} de ${scheduledPeriods.getPeriodLabel(scheduledParameters.period)}`;
};

export const reviewDeliveryLabel = ({
  deliveryType,
  time,
  scheduledParameters
}) => {
  if (deliveryType === deliveryChoices.scheduledDelivery) {
    return scheduledReviewMessage(scheduledParameters);
  }

  if (deliveryType === deliveryChoices.pickupInStore) {
    return pickupInStoreReviewMessage(time);
  }

  return defaultReviewMessage(time);
};

export const documentNumber = (value) => {
  return value && value.substr(0, 20);
};

export const giftCardMessage = (message) => {
  return message && message.substr(0, 360);
};

export const nationality = (value) => {
  return value && value.substr(0, 20);
};

export const maritalStatus = (value) => {
  return value && value.substr(0, 20);
};

export const rg = (value) => {
  const removedSpecial = cleaners.removeSpecialChars(value, '-.');
  return removedSpecial.substr(0, 20);
};

export const rgState = (value) => {
  return value && value.substr(0, 2);
};

export const rgAuthority = (value) => {
  const removedSpecial = cleaners.removeSpecialChars(value, '-.');
  return removedSpecial.substr(0, 50);
};

export const income = (value) => {
  const onlyNumbers = cleaners.onlyNumbers(value);
  return onlyNumbers && VMasker.toMoney(onlyNumbers, { unit: 'R$' });
};

export const verifySpecialSkus = (blackListedSku, skuList) => {
  for (let i = 0; i < blackListedSku.length; i++) {
    for (let x = 0; x < skuList.length; x++) {
      if (blackListedSku[i] === skuList[x]) {
        return true;
      }
    }
  }
  return false;
};

export const gender = (value) => {
  return value && value.substr(0, 20);
};

export const handleLockBackgroundScroll = (value) => {
  document.querySelector('html').style.overflow = value;
};

export const pin = (value) => {
  const onlyNumbers = cleaners.onlyNumbers(value);
  return onlyNumbers && VMasker.toPattern(onlyNumbers, '999999');
};
