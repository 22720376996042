import * as AddressApi from 'api/address-api';
import * as customerMessages from 'constants/customer-messages';
import { fail } from './fail';
import { unauthorizedLogout } from './unauthorized';
import { metricsError } from './metrics';

export const REQUEST = 'address/REQUEST';
export const RECEIVED = 'address/RECEIVED';
export const EDIT = 'address/EDIT';
export const DISMISS_EDIT = 'address/DISMISS-EDIT';
export const SET = 'address/SET';
export const SAVE = 'address/SAVE';
export const DID_SAVE = 'address/DID-SAVE';
export const RESET = 'address/RESET';
export const FINISHED = 'address/FINISHED';
export const DID_SET_ADDRESS = 'address/DID-SET-ADDRESS';

export const initialState = {
  addressUuid: null,
  isRequesting: false,
  zipcode: '',
  address: '',
  neighbourhood: '',
  city: '',
  state: '',
  reference: '',
  isDefault: false,
  number: '',
  complement: '',
  telephone: '',
  receiver: '',
  editUuid: '',
  noNumber: false,
  setCustomerAddress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { address, zipcode, editUuid } = action;

  switch (action.type) {
    case REQUEST:
      return Object.assign({}, state, {
        isRequesting: true,
        zipcode,
      });
    case RECEIVED:
      return Object.assign({}, state, {
        isRequesting: false,
        ...address,
      });
    case EDIT:
      return Object.assign({}, state, { editUuid });
    case DISMISS_EDIT:
      return Object.assign({}, state, { editUuid: '' });
    case SAVE:
      return Object.assign({}, state, {
        isRequesting: true,
        ...address,
      });
    case SET:
      return Object.assign({}, state, {
        ...address,
      });
    case DID_SAVE:
      return Object.assign({}, state, { isRequesting: false });
    case RESET:
      return Object.assign({}, initialState);
    case FINISHED:
      return Object.assign({}, state, { isRequesting: false });
    case DID_SET_ADDRESS:
      return Object.assign({}, state, { isRequesting: false });
    default:
      return state;
  }
}

export function requestAddress(zipcode) {
  return { type: REQUEST, zipcode };
}

export function receiveAddress(address) {
  return { type: RECEIVED, address };
}

export function editAddress(editUuid) {
  return { type: EDIT, editUuid };
}

export function dismissEditAddress() {
  return { type: DISMISS_EDIT };
}

export function resetAddress() {
  return { type: RESET };
}

export function setAddress(address) {
  return { type: SET, address };
}

export function saveAddress(address) {
  return { type: SAVE, address };
}

export function didSaveAddress() {
  return { type: DID_SAVE };
}

export function finish() {
  return { type: FINISHED };
}

export function didSetAddress() {
  return {
    type: DID_SET_ADDRESS,
  };
}

export function fetchAddress(zipcode, ignoreErrors = false) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { address = {} } = getState();

      if (address.isRequesting) {
        return reject('Already doing address request.');
      }

      dispatch(requestAddress(zipcode));

      return AddressApi.getAddress(zipcode).then(
        (response) => {
          dispatch(receiveAddress(response));
          return resolve(response);
        },
        (err) => {
          if (!ignoreErrors) {
            dispatch(fail(customerMessages.failFetchAddress, err.status));
          }

          if (
            err &&
            err.response &&
            err.response.body &&
            err.response.body.error_message
          ) {
            dispatch(
              metricsError('cep cadastro', err.response.body.error_message.error_message)
            );
          }

          dispatch(receiveAddress({ ...initialState, zipcode }));
          return reject(err);
        }
      );
    });
  };
}

export function putAddress(address, ignoreErrors = false) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { address: addressState = {} } = getState();

      if (addressState.isRequesting) {
        return reject('Already doing address request.');
      }

      dispatch(saveAddress(address));

      return AddressApi.putAddress(address).then(
        (res) => {
          dispatch(didSaveAddress());
          return resolve(res);
        },
        (err) => {
          const { status } = err;

          let messageError = customerMessages.failSaveAddress;

          if (status === 401) {
            dispatch(unauthorizedLogout(err));
            messageError = customerMessages.expired;
          }

          if (status === 406) {
            messageError = customerMessages.incompleteRegister;
          }

          if (!ignoreErrors) {
            dispatch(fail(messageError, status));
          }

          dispatch(finish());
          return reject(err);
        }
      );
    });
  };
}

export function patchAddress(addressUuid, ignoreErrors = false) {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const { address: addressState = {} } = getState();

    if (addressState.isRequesting) {
      return reject('Already doing some basket request.');
    }

    dispatch(setAddress({ addressUuid }));

    return AddressApi.patchAddress(addressUuid).then(
      () => {
        dispatch(didSetAddress());
        return resolve();
      },
      (err) => {
        const { status } = err;

        if (status === 401) {
          dispatch(unauthorizedLogout(err));
          dispatch(fail(
            customerMessages.expired,
            status
          ));
        } else if (status === 406 && !ignoreErrors) {
          dispatch(fail(customerMessages.incompleteRegister, status));
        } else if (status !== 400 && !ignoreErrors) {
          dispatch(fail(customerMessages.failSetAddress, status));
        }

        return reject(err);
      });
  });
}
