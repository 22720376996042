import React, { Component } from 'react';
import PropTypes from 'prop-types';
import partition from 'lodash/partition';
import Responsive from 'components/Responsive';
import AddressBox from 'components/Address/AddressBox/AddressBox';

import './AddressList.scss';
import { addressSelectContent } from 'utils/data-layer/helpers';

class AddressList extends Component {
  static propTypes = {
    addressList: PropTypes.array.isRequired,
    showEditAddress: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func,
    onNewAddress: PropTypes.func,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onSetDefault: PropTypes.func,
    addressUuid: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedAddressUuid: props.addressUuid || null,
    };
  }

  separateAdresses = (items) => {
    const [[defaultAddress], otherAddresses] = partition(
      items,
      (item) => !!item.isDefault
    );

    return { defaultAddress, otherAddresses };
  };

  getSelectedId = () => {
    const { addressUuid } = this.props;
    const { selectedAddressUuid } = this.state;

    return selectedAddressUuid || addressUuid;
  }

  getAddressObject = (id = null) => {
    const { addressList } = this.props;

    return addressList.find(({ addressUuid }) => addressUuid === id) || null;
  };

  renderNewAddressButton = () => {
    const { onNewAddress } = this.props;
    if (!onNewAddress) {
      return null;
    }

    return (
      <button className="AddressList-newAddressButton" onClick={onNewAddress}>
        <i className="ti ti-add" />
        Adicionar novo endereço
      </button>
    );
  };

  handleSelectItem = (item) => {
    const contentIdLabel = item.isDefault ? 'padrao' : 'outros-enderecos';

    addressSelectContent({
      contentId: `selecionou: ${contentIdLabel}`,
      contentType: 'lista-de-enderecos',
    });

    this.setState({ selectedAddressUuid: item.addressUuid });
  };

  renderAddressItem = (item) => {
    const { onEdit, showEditAddress, onDelete, onSetDefault } = this.props;
    const selectedId = this.getSelectedId();

    return (
      <AddressBox
        data={item}
        key={item.addressUuid}
        showEdit={showEditAddress}
        selected={selectedId === item.addressUuid}
        onSelect={() => this.handleSelectItem(item)}
        onEdit={onEdit}
        onDelete={onDelete}
        onSetDefault={onSetDefault}
      />
    );
  };

  handleConfirm = () => {
    const { onConfirm } = this.props;
    const selectedId = this.getSelectedId();
    const selectedAddress = this.getAddressObject(selectedId);

    if (!selectedAddress) {
      return;
    }

    onConfirm(selectedAddress);
  };

  render() {
    const { addressList, onConfirm } = this.props;
    const selectedId= this.getSelectedId();
    const { defaultAddress, otherAddresses } = this.separateAdresses(addressList);
    const hasSelectedAddress = !!selectedId && !!this.getAddressObject(selectedId);

    return (
      <div className="radio-group flex-col items-start px-sm md:px-0 max-w-[744px]">
        {!!defaultAddress && <p className="AddressList-subtitle">Endereço padrão</p>}
        {!!defaultAddress && this.renderAddressItem(defaultAddress)}
        {!!defaultAddress && !!otherAddresses.length && (
          <p className="AddressList-subtitle">Outros Endereços</p>
        )}
        {otherAddresses.map(this.renderAddressItem)}
        <div className="AddressList-buttonsContainer">
          {this.renderNewAddressButton()}
          {onConfirm && (
            <button
              className="AddressList-confirmButton"
              onClick={this.handleConfirm}
              disabled={!hasSelectedAddress}>
              Continuar
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default Responsive(AddressList);
