import React from 'react';
import PropTypes from 'prop-types';
import * as maskers from 'utils/maskers';
import './AddressBox.scss';

const AddressBox = ({
  data,
  onSelect,
  onEdit,
  showEdit,
  onDelete,
  onSetDefault,
  selected,
}) => {
  const {
    receiver,
    address,
    neighbourhood,
    city,
    state,
    reference,
    number,
    complement,
    zipcode,
    addressUuid,
    isDefault,
  } = data;

  return (
    <div className="AddressBox">
      <input
        type="radio"
        className="self-center"
        id={`address-${addressUuid}`}
        name="address"
        checked={selected}
        onChange={() => onSelect(data)}
        value={addressUuid}
      />
      <span className="ripple" />

      <label htmlFor={`address-${addressUuid}`} className="AddressBox-label">
        <div className="AddressBox-title">{receiver}</div>
        <div className="AddressBox-address">
          {maskers.fullAddress(
            address,
            neighbourhood,
            city,
            state,
            number,
            complement,
            reference
          )}
        </div>
        <div className="AddressBox-zipcode">CEP: {maskers.zipcode(zipcode)}</div>
        <div className="flex pt-2xsm">
          {!isDefault && onSetDefault && (
            <button className="btn-invisible mr-lg" onClick={() => onSetDefault(data)}>
              Tornar padrão
            </button>
          )}
          {showEdit && onEdit && (
            <button className="btn-invisible mr-md" onClick={() => onEdit(data)}>
              Editar
            </button>
          )}
          {onDelete && (
            <button className="btn-invisible" onClick={() => onDelete(data)}>
              Excluir
            </button>
          )}
        </div>
      </label>
    </div>
  );
};

AddressBox.propTypes = {
  data: PropTypes.shape({
    city: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    complement: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    reference: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    neighbourhood: PropTypes.string.isRequired,
    zipcode: PropTypes.string.isRequired,
    receiver: PropTypes.string.isRequired,
    addressUuid: PropTypes.string.isRequired,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onSetDefault: PropTypes.func,
  showEdit: PropTypes.bool.isRequired,
};

export default AddressBox;
