import React from 'react';
import Proptypes from 'prop-types';

import { validateKeyPress, validatePastedContent, getActionByKey } from './keys';

import './InputPinCode.scss';

const NextInput = 'NextInput';
const Backspace = 'Backspace';
const Delete = 'Delete';

class InputPinCode extends React.Component {
  constructor(props) {
    super(props);
    this.pinInput = [];
  }

  componentDidUpdate(prevProps) {
    const { value, options, onComplete } = this.props;
    if (
      (prevProps.value !== value)
      && (value.length === options.size)
    ) {
      onComplete(this.props.value);
    }

    this.handleFocusFirstEmptyInput();
  }

  componentDidMount() {
    this.pinInput[0].focus();
  }

  handleFocusFirstEmptyInput() {
    const emptyInput = this.pinInput.find(input => !input.value);
    if (emptyInput) emptyInput.focus();
  }

  applyAction(action, currentIdx, event) {
    const { value } = this.props;
    switch (action) {
      case NextInput: {
        return this.handleFocusFirstEmptyInput(event, currentIdx);
      }
      case Backspace: {
        const newPin = value.slice(0, -1);
        this.props.onChange(newPin);
        return this.handleFocusFirstEmptyInput(event, currentIdx);
      }
      case Delete: {
        this.props.onChange('');
        return this.handleFocusFirstEmptyInput(event, currentIdx);
      }

      default: {
        return this.props.onComplete(value);
      }
    }
  }

  updatePinValue(providedValue) {
    const currentPin = this.props.value;
    const formatNewValue = currentPin
      ? currentPin.concat(providedValue)
      : providedValue;

    this.props.onChange(formatNewValue);
  }

  checkIfKeyIsDeleteOrSpaceAction(event, idx) {
    const action = getActionByKey(event.keyCode);
    if (action) this.applyAction(action, idx, event);
  }

  handleKeyDown(event, idx) {
    this.checkIfKeyIsDeleteOrSpaceAction(event, idx);
  }

  handleOnChange(event, idx) {
    const key = validateKeyPress(event, this.props.options.onlyNumbers);
    if (key) {
      this.updatePinValue(key);

      if (idx < this.props.options.size - 1) this.applyAction(NextInput, idx, event);
    }
  }

  handlePaste(event, idx) {
    const endIndex = idx === 0 ? this.props.options.size : idx;
    const pastedContent = event.clipboardData
      .getData('Text')
      .slice(0, endIndex);

    const isValidContent = validatePastedContent(
      pastedContent,
      this.props.options.onlyNumbers
    );

    if (isValidContent) this.updatePinValue(pastedContent);
  }

  render() {
    const { success, error, value } = this.props;

    return (
      <div className="InputPinCode-wrapper">
        <form className="InputPinCode-form">
          {[...Array(this.props.options.size).keys()].map((idx) => (
            <input
              key={idx}
              maxLength={1}
              autoCorrect="off"
              autoComplete="off"
              autoCapitalize="off"
              onPaste={(e) => this.handlePaste(e, idx)}
              ref={(inputEl) => (this.pinInput[idx] = inputEl)}
              onKeyDown={(e) => this.handleKeyDown(e, idx)}
              onChange={(e) => this.handleOnChange(e, idx)}
              inputMode="numeric"
              onFocus={(e) => this.handleFocusFirstEmptyInput(e)}
              className={`InputPinCode-input ${error.statusCode !== '' && 'InputPinCode-error'} ${success && 'InputPinCode-success'}`}
              value={value?.[idx] || ''}
            />
          ))}
        </form>

        {error.statusCode !== '' &&
          <div className="InputPinCode-wrapper-error">
            <span className="ti ti-error-outline" />
            <span className="InputPinCode-message-error">{error.message}</span>
          </div>}
      </div>
    );
  }
}

InputPinCode.propTypes = {
  success: Proptypes.bool,
  options: Proptypes.shape({
    size: Proptypes.number,
    onlyNumbers: Proptypes.bool,
  }),
  error: Proptypes.shape({
    statusCode: Proptypes.oneOfType([
      Proptypes.string,
      Proptypes.number
    ]),
    message: Proptypes.string,
  }),
  onComplete: Proptypes.func.isRequired,
};

InputPinCode.defaultProps = {
  options: {
    size: 6,
    onlyNumbers: true,
  },
};

export default InputPinCode;
