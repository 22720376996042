import { getCustomer } from 'utils/session';
import { GTM_UNDEFINED, productsData, productsDataGA4 } from './helpers';
import getBasketTaxes from './helpers/getBasketTaxes';

export const purchaseLayerGA4 = (stateAfter) => {
  const layer = {};
  const order = stateAfter?.order?.order;
  const { total } = productsData(order.items, stateAfter);
  const { tax } = getBasketTaxes(stateAfter?.basket?.basket?.totals);
  const products = stateAfter?.basket?.basket?.products;

  const eventName = 'purchase';
  layer.event = eventName;
  layer.screenName = 'pedido-concluido';
  layer.value = total;
  layer.mundos = 'magalu';

  const customer = getCustomer();
  layer.idUsuario = customer?.id || null;
  layer.basketId = stateAfter?.basket?.basket?.id || null;
  layer.newBuyer = (order?.isNewBuyer ?? GTM_UNDEFINED).toString() || null;
  layer.payment_type = `${order.payment.payment_method_id}:${order.payment.payment_method_id}`;

  layer.parcelas =
    order?.payment?.payment_method_id === 'pix'
      ? order?.payment?.number_of_installments
      : null;
  layer.shipping = order?.shippingAmount ?? null;
  layer.affiliation = 'site_magalu';
  layer.currency = 'BRL';
  layer.transaction_id = order.id;
  layer.items = productsDataGA4({
    products,
    stateAfter,
    index: null,
    eventName,
  });
  layer.tax = tax;

  return layer;
};
