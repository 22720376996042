import { addToDataLayer } from '../../../app/store/data-layer-middleware';

export const addressSelectContent = ({ contentId, contentType } = {}) => {
  const layer = {
    event: 'select_content',
    content_type: contentType,
    content_id: contentId,
  };

  return addToDataLayer(layer);
};

export const addressViewContent = ({ contentId } = {}) => {
  const layer = {
    event: 'view_content',
    content_type: 'formulario-enderecos',
    content_id: contentId,
  };

  return addToDataLayer(layer);
};
