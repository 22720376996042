import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './DialogBottomSheet.scss';

class DialogBottomSheet extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    footerContent: PropTypes.node,
  };

  handleClose = (e) => {
    const { onClose } = this.props;
    e?.preventDefault?.();
    onClose?.();
  };

  handleEsc(event) {
    event.preventDefault();
    if (event.key === 'Escape') {
      this.handleClose();
    }
  }

  componentDidUpdate() {
    const { open } = this.props;
    if (!open) {
      return document.removeEventListener('keydown', this.handleEsc.bind(this));
    }

    document.addEventListener('keydown', this.handleEsc.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEsc.bind(this));
  }

  render() {
    const { title, children, open, footerContent, onClose } = this.props;

    return (
      <div className={`bottom-sheet ${open ? 'visible' : ''}`}>
        <div
          className={`bottom-sheet-overlay ${!!onClose ? 'cursor-pointer' : ''}`}
          onClick={this.handleClose}
        />
        <div className="bottom-sheet-container md:!h-fit md:max-w-[360px] md:left-1/2 md:top-1/2 md:!-translate-x-1/2 md:!-translate-y-1/2 md:rounded-2xlg">
          <header className="bottom-sheet-header !justify-center font-md-bold">
            {title}
            {!!onClose && (
              <button
                onClick={this.handleClose}
                className="bottom-sheet-close !absolute right-md"
              />
            )}
          </header>
          <section className="bottom-sheet-content">{children}</section>
          {footerContent && (
            <footer className="bottom-sheet-footer">{footerContent}</footer>
          )}
        </div>
      </div>
    );
  }
}

DialogBottomSheet.defaultProps = {
  footerContent: null,
};

export default DialogBottomSheet;
