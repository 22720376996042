export const CUSTOMER_COOKIE = 'ml2_sid_c';
export const CUSTOMER_COOKIE_CLUBE = 'ml2_sid_c_clube';
export const ZIPCODE_COOKIE = 'zipcode';
export const CUSTOMER_SESSION = 'ml_s';
export const NATIONAL_STORAGE_KEY = 'isNational';
export const GUEST_DOCUMENT = 'fromGuestDocument';
export const NATIONAL_RESET_PASSWORD_IDENTIFICATION = 'nationalResetPasswordIdentification';
export const CUSTOMER_STORAGE_DATA = 'nationalCustomerData';
export const SHIPPING_STORAGE_DATA = 'shipping_v2';
export const COOKIE_TYPE_CUSTOMER = 'customer';
export const COOKIE_TYPE_ZIPCODE = 'zipcode';
export const COOKIE_ZIPCODE_DELETED = 'zipcodeDeleted';
export const WISHLIST_COOKIE = 'jeannie';
export const STWU = 'stwu';
