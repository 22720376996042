import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';
import omit from 'lodash/omit';

import { scheduledDelivery as scheduledDeliveryChoice } from 'constants/delivery-choices';

export const basketWarranty = (warranty) => {
  return (
    warranty && {
      name: `${warranty.description} de ${warranty.duration / 12} ano`,
      price: warranty.total_unit_price,
    }
  );
};

export const serviceDescription = (category, durationValue) => {
  if (!durationValue || durationValue < 0) {
    return '';
  }

  const duration = durationValue / 12;

  if (category === 'TC') {
    return `Troca Certa de ${duration} ano${(duration > 1 && 's') || ''}`;
  } else if (category === 'RF') {
    return `Proteção Roubo e Furto qualificado com quebra acidental de ${duration} ano${
      (duration > 1 && 's') || ''
    }`;
  } else if (category === 'GE') {
    return `Garantia Estendida de ${duration} ano${(duration > 1 && 's') || ''}`;
  } else if (category === 'RFSQA') {
    return `Proteção Roubo e Furto qualificado de ${duration} ano${
      (duration > 1 && 's') || ''
    }`;
  }
};

export const basketService = (service) => {
  return (
    service && {
      name: serviceDescription(service.category, service.duration.value),
      price: service.total_unit_price,
      unitPrice: service.price,
      id: service.id,
      category: service.category,
      treeId: service.tree_id,
      duration: service.duration.value,
    }
  );
};

export const additionalProduct = (item) => {
  return (
    item && {
      id: item.sku,
      title: item.name,
      quantity: item.quantity,
    }
  );
};

export const variationProduct = (variation) => {
  return (
    variation && {
      key: variation.key,
      value: variation.value,
    }
  );
};

export const basketProduct = (item) => {
  let product = {
    title: item.product.name,
    prices: {
      to: item.total_unit_price,
      cash: item.total_checkout_price,
      checkoutPriceDescription: item.checkout_price_description,
      unitPriceDescription: item.unit_price_description,
    },
    store: item.seller_description,
    storeId: item.seller,
    id: item.sku,
    sku: item.sku,
    rawPictureUrl: item.product.image_url,
    fullPictureUrl: item.product.image,
    reference: item.product.reference,
    quantity: item.quantity,
    availability: item.product.shipping_availability,
    isSoldOut: item.out_of_stock_product,
    notAvailableForDelivery: item.not_available_for_delivery,
    productUrl: item.product.product_url,
    subcategoryUrl: item.product.subcategory_url,
    categoryId: item.product.category_id,
    categoryName: item.product.category_name,
    subcategoryId: item.product.subcategory_id,
    subCategoryName: item.product.subcategory_name,
    brand: item.product.brand,
    time: item.delivery_time,
    currency: item.currency,
    tax: {
      icms: item?.foreigner_tax?.value_icms,
      importValue: item?.foreigner_tax?.value_import_tax,
      iof: item?.foreigner_tax?.value_iof,
    },
    variations:
      (item.product.variations && item.product.variations.map(variationProduct)) || [],
    services: (item.services.length > 0 && item.services.map(basketService)) || [],
    provider: item.product.provider,
    providerDescription: item.product.provider_description,
    maxQuantity: item.max_quantity || null,
    minQuantity: item.minimum_order_quantity || null,
  };

  const warranty = basketWarranty(item.warranty);

  if (warranty) {
    product = Object.assign({}, product, { warranty });
  }

  const gifts = item.gifts && item.gifts.map(additionalProduct);

  if (gifts) {
    product = Object.assign({}, product, { gifts });
  }

  const bundles = item.bundles && item.bundles.map(additionalProduct);

  if (bundles) {
    product = Object.assign({}, product, { bundles });
  }

  return product;
};

export const basket = (payload = {}) => {
  const deliveries_ = payload.deliveries;

  return {
    products: payload.items.map(basketProduct),
    productCount: payload.total_number_products,
    totals: {
      itemsCatalogAmount: payload.totals.items_catalog_amount,
      to: payload.totals.amount,
      cash: payload.totals.checkout_amount,
      shipment: payload.total_delivery_cost,
      amountDescription: payload.totals.amount_description,
      checkoutAmountDescription: payload.totals.checkout_amount_description,
      amountWithoutDiscount: payload.totals.items_catalog_amount,
      totalDelivery: payload.total_delivery_cost,
      icms: payload?.totals?.foreigner_tax?.total_icms,
      importValue: payload?.totals?.foreigner_tax?.total_import_tax,
      remainingCreditAmount: payload?.totals?.remaining_credit_amount,
    },
    id: payload.id,
    customerId: payload.customer_id,
    customerEmail: payload.customer_email,
    customerPerson: payload.customer_person,
    hasServices: payload.has_services,
    campaignCode: payload.campaign_code,
    packages: deliveries_,
    unavailableDeliveries: payload.unavailable_deliveries,
  };
};

export const deleteServiceData = (services = []) => {
  return services.map((service) => {
    return {
      item_sku: service.id,
      seller: service.storeId,
      services: service.services,
      service: service.service,
    };
  });
};

export const scheduledDeliveryData = ({
  cost,
  description,
  price,
  id,
  time,
  parameters,
  delivery_parameters,
}) => ({
  cost,
  description,
  price,
  id,
  time,
  parameters,
  delivery_parameters,
});

export const shippingProduct = ({
  product: { name: title, reference, variations },
  quantity,
  warranty,
  gifts,
  bundles,
  services,
  sku,
  currency,
}) => ({
  quantity,
  title,
  reference,
  gifts: (gifts && gifts.map(additionalProduct)) || [],
  bundles: (bundles && bundles.map(additionalProduct)) || [],
  currency,
  warranty: (warranty && basketWarranty(warranty)) || {},
  variations: (variations && variations.map(variationProduct)) || [],
  services: (services.length > 0 && services.map(basketService)) || [],
  sku,
});

export const shippingAddress = ({
  number,
  address,
  state,
  reference,
  complement,
  city,
  recipient_name: receiver,
  zip_code,
}) => ({
  number,
  address,
  state,
  reference,
  complement,
  city,
  receiver,
  zipcode: zip_code,
});

export const storeAddress = ({
  store_address: address,
  store_number: number,
  store_state: state,
  store_city: city,
}) => ({
  number,
  address,
  state,
  city,
});

export const scheduledParameters = ({ scheduled_date: date, time_slot: period }) => ({
  date,
  period,
});

// shippingPackage is used for stores too.
export const shippingPackage = ({
  delivery_time: time,
  delivery_type_id: deliveryTypeId,
  delivery_type: { parameters, zip_code_restriction, type: deliveryType },
  products,
  promotional_date: promotionalDate,
  price,
}) => {
  const deliveryTypes = [
    {
      type: deliveryType,
      price,
      time,
    },
  ];

  return {
    time,
    deliveryTypeId,
    deliveryType,
    promotionalDate,
    scheduledParameters:
      (deliveryType === scheduledDeliveryChoice && scheduledParameters(parameters)) || {},
    store: products[0].seller_description,
    products: products.map(shippingProduct),
    zipcodeRestriction: zip_code_restriction,
    price,
    deliveryTypes,
  };
};

export const shippingDeliveries = ({ address = {}, packages = [] }) => ({
  address: shippingAddress(address),
  packages: packages.map(shippingPackage),
});

export const shippingStore = (store) => {
  const storeId = Object.keys(store)[0];
  const storeById = Object.values(store)[0];

  return {
    [storeId]: {
      packages: storeById.packages.map(shippingPackage),
      address: storeAddress(storeById.address),
    },
  };
};

export const giftCard = (giftCardInfo) => {
  if (!giftCardInfo) {
    return {};
  }

  const {
    from_name: from = '',
    to_name: to = '',
    message = '',
    cpf_cnpj: cpfOrCnpj = '',
    state_inscription: stateInscription = '',
  } = giftCardInfo;
  return {
    from,
    to,
    message,
    cpfOrCnpj,
    stateInscription,
  };
};

export const creditTotal = (credit) => ({
  totalAmount: credit.total_amount,
  totalAvailableAmount: credit.total_available_amount,
  totalRemainingAmount: credit.total_remaining_amount,
  remainingCartAmount: credit.remaining_cart_amount,
});

export const availableCreditList = (credits) => {
  if (!credits) {
    return [];
  }

  return credits.map((credit) => ({
    id: credit.id,
    name: credit.name,
    totalAmount: credit.total_amount,
    remainingAmount: credit.remaining_amount,
    availableAmount: credit.available_amount,
  }));
};

const item_ = (item) => {
  return {
    ...item,
    title: item.product?.name,
    reference: item.product?.reference,
    bundles: (item.bundles && item.bundles.map(additionalProduct)) || [],
    gifts: (item.gifts && item.gifts.map(additionalProduct)) || [],
    warranty: (item.warranty && basketWarranty(item.warranty)) || {},
    variations:
      (item.product?.variations && item.product.variations.map(variationProduct)) || [],
    services:
      (item.services && item.services.length > 0 && item.services.map(basketService)) ||
      [],
  };
};

const delivery_ = (payload = {}) => {
  const { items, modality, id: deliveryId } = payload;

  const {
    id: modalityId,
    name,
    amount,
    type,
    shipping_time: { min_value: minValue = 0, description } = {},
    delivery_parameters: deliveryParameters,
    zip_code_restriction: zipcodeRestriction,
  } = modality;

  return {
    price: amount,
    cost: amount,
    products: items.map(item_),
    zipcodeRestriction,
    time: minValue,
    store: items[0].seller_description,
    deliveryTypeId: modalityId.toString(),
    deliveryTypeDescription: name,
    deliveryType: type,
    scheduledParameters: type === 'scheduled' ? deliveryParameters : {},
    shippingTimeDescription: description,
    deliveryId,
  };
};

const store_ = (payload = {}) => {
  const { items, modality } = payload;

  const {
    id: modalityId,
    name,
    amount,
    type,
    shipping_time: { min_value: minValue, description } = {},
    delivery_parameters: {
      pickup_address: { id: storeId, ...restAddress },
    },
    zip_code_restriction: zipcodeRestriction,
  } = modality;

  return {
    [storeId]: {
      address: restAddress,
      packages: [
        {
          price: amount,
          cost: amount,
          products: items.map(item_),
          zipcodeRestriction,
          time: minValue,
          store: items[0].seller_description,
          deliveryTypeId: modalityId.toString(),
          deliveryTypeDescription: name,
          deliveryType: type,
          scheduledParameters: {},
          shippingTimeDescription: description,
        },
      ],
    },
  };
};

const deliveriesFormat = (deliveries = []) =>
  deliveries.filter((d) => d.modality.type !== 'store_pickup').map(delivery_);

const storesFormat = (stores = []) =>
  stores
    .filter((s) => s.modality.type === 'store_pickup')
    .map(store_)
    .reduce((acc, cur) => {
      const storeId = Object.keys(cur)[0];
      const arrayIndex = acc.findIndex((a) => Object.keys(a)[0] === storeId);

      if (
        acc
          .map((a) => Object.keys(a))
          .flat()
          .includes(storeId)
      ) {
        acc[arrayIndex] = {
          [storeId]: {
            ...acc[arrayIndex][storeId],
            packages: [...acc[arrayIndex][storeId].packages, ...cur[storeId].packages],
          },
        };
        return acc;
      }

      return [...acc, cur];
    }, []);

export const paymentReview = ({
  total_amount,
  total_products,
  total_packages: totalPackages,
  shipping_price,
  shipping_packages: { deliveries: packages = {}, stores = [] },
  has_marketplace_items: hasMarketplaceItems,
  is_person: isPerson,
  gift_card: giftCardInfo,
  credits = {},
  use_credit,
  promocode,
  discounts,
  campaign_code,
  items_sku,
  totals: {
    credit_amount_in_use,
    checkout_amount,
    remaining_credit_amount,
    remaining_amount,
    items_catalog_amount,
    catalog_amount: catalogAmount,
    shipping_amount: shippingAmount,
    amount_description,
    checkout_amount_description,
    services_quantity,
    services_amount,
    foreigner_tax,
  },
  shipping_address: address = {},
  deliveries = [],
}) => {
  let shippingTypesDeliveries = shippingDeliveries(packages);
  let shippingTypesStores = stores.map(shippingStore);

  if (deliveries.length) {
    shippingTypesDeliveries = {
      address,
      packages: deliveriesFormat(deliveries),
    };
    shippingTypesStores = storesFormat(deliveries);
  }

  const taxes = {
    tax: {
      icms: foreigner_tax?.total_icms,
      importValue: foreigner_tax?.total_import_tax,
      iof: foreigner_tax?.total_iof,
    },
  };

  return {
    shippingPackages: {
      totals: {
        products: items_catalog_amount,
        shipment: shipping_price,
        to: total_amount,
        cash: checkout_amount,
        amountDescription: amount_description,
        checkoutAmountDescription: checkout_amount_description,
        servicesQuantity: services_quantity,
        servicesAmount: services_amount,
        ...(foreigner_tax && { ...taxes }),
      },
      shippingTypes: {
        deliveries: shippingTypesDeliveries,
        stores: shippingTypesStores,
      },
      packagesCount: totalPackages,
      hasMarketplaceItems,
      accountType: (isPerson && 'pf') || 'pj',
      giftCardInfo: giftCard(giftCardInfo),
    },
    campaignCode: campaign_code,
    itemsSku: items_sku,
    credits: creditTotal(credits),
    availableCredits: availableCreditList(credits.available_credits),
    useCredit: use_credit,
    promocode,
    discounts,
    totals: {
      creditAmountInUse: credit_amount_in_use,
      remainingCreditAmount: remaining_credit_amount,
      totalAmount: total_amount,
      checkoutAmount: checkout_amount,
      totalCheckoutAmount: checkout_amount,
      remainingAmount: remaining_amount,
      totalProducts: total_products,
      shippingPrice: shipping_price,
    },
  };
};
