import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DialogBottomSheet from 'components/DialogBottomSheet';

class ErrorModal extends Component {
  static propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    closeText: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onRetry: PropTypes.func,
  };

  static defaultProps = {
    title: 'Ocorreu uma falha',
    message: 'Por favor, aguarde alguns instantes e tente novamente.',
    closeText: 'Fechar',
  };

  handleRetry = () => {
    const { onClose, onRetry } = this.props;
    onRetry?.();
    onClose?.();
  };

  handleClose = (e) => {
    const { onClose } = this.props;
    e?.preventDefault?.();
    onClose?.();
  };

  render() {
    const { closeText, message, title, open, onClose, onRetry } = this.props;

    return (
      <DialogBottomSheet
        title={title}
        open={open}
        onClose={onClose}
        footerContent={
          <div className="flex flex-col items-center">
            {!!onRetry && (
              <button
                className="btn btn-outline flex justify-center w-[170px] mb-xsm"
                onClick={this.handleRetry}>
                Tentar novamente
              </button>
            )}
            {!!onClose && (
              <button className="btn flex justify-center w-[170px]" onClick={this.handleClose}>
                {closeText}
              </button>
            )}
          </div>
        }>
        <p className="text-center">
          {message}
        </p>
      </DialogBottomSheet>
    );
  }
}

export default ErrorModal;
