export const sendAdsEventsMutation = (input) => {
  const superagent = require('superagent');

  return new Promise((resolve, reject) => {
    superagent
      .post(SETTINGS.MAGA_GQL_FEDERATION_ENDPOINT)
      .set('Content-Type', 'application/json')
      .send({
        query: `
          mutation Mutation($input: [SendAdsEventsInput!]!) {
            sendAdsEvents(input: $input) {
              status
            }
          }
        `,
        variables: { input }
      })
      .end((err, res) => {
        if (err) {
          reject(err);
        } else {
          resolve(res.body);
        }
      });
  });
};
