import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import './Input.scss';

export default class Input extends Component {
  render() {
    const {
      name,
      label,
      placeholder,
      helperText,
      error,
      value,
      mask,
      id,
      type,
      maxLength,
      disabled,
      children,
      touched,
      invalid,
      onFocus,
      onBlur,
      onChange,
      className,
    } = this.props;

    const inputValue = mask ? mask(value) : value;
    const hasError = !!error && invalid && (touched || inputValue) && !disabled;
    const hasHelperText = (!!helperText || hasError) && !disabled;

    return (
      <div
        className={classname('Input-group input-group w-full', {
          error: hasError,
          disabled: !!disabled,
          [className]: !!className,
        })}>
        <label
          className={classname({ '!text-on-surface-4': disabled })}
          id="label-input"
          htmlFor={`input-${id || name}`}>
          {label}
        </label>
        {children}
        <input
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          id={`input-${id || name}`}
          data-testid="input"
          className={classname('Input-input input-text max-w-full', {
            'text-on-surface-4': disabled,
            '!bg-surface-container-lower': disabled,
            'Input--withError': hasError,
          })}
          type={type}
          placeholder={placeholder}
          value={inputValue}
          disabled={disabled}
          maxLength={maxLength}
        />
        {hasHelperText && (
          <span
            className="input-info icon-info"
            data-testid="input-helper-text"
            id="input-helper-text">
            {error || helperText}
          </span>
        )}
      </div>
    );
  }
}

Input.propTypes = {
  onBlur: PropTypes.func,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.string.isRequired,
  mask: PropTypes.func,
  onfocus: PropTypes.func,
  id: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  children: PropTypes.node,
  touched: PropTypes.bool,
  invalid: PropTypes.bool,
  className: PropTypes.string,
};

Input.defaultProps = {
  placeholder: '',
  name: undefined,
  onBlur: undefined,
  helperText: undefined,
  error: undefined,
  onfocus: undefined,
  mask: undefined,
  id: undefined,
  type: 'text',
  maxLength: undefined,
  disabled: false,
  children: undefined,
  touched: false,
  invalid: false,
  className: undefined,
};